@import '~bootstrap/scss/bootstrap';
@font-face {
  font-family: "Benzin-Regular";
  font-style: normal;
  font-weight: normal;
  src: local('Benzin-Regular'), url('../src/assets/fonts/Benzin-Regular.woff') format('woff');
}
@font-face {
  font-family: "Benzin-Medium";
  font-style: normal;
  font-weight: normal;
  src: local('Benzin-Medium'), url('../src/assets/fonts/Benzin-Medium.woff') format('woff');
}
@font-face {
  font-family: "Benzin-Semibold";
  font-style: normal;
  font-weight: normal;
  src: local('Benzin-Semibold'), url('../src/assets/fonts/Benzin-Semibold.woff') format('woff');
}
@font-face {
  font-family: "Benzin-Bold";
  font-style: normal;
  font-weight: normal;
  src: local('Benzin-Bold'), url('../src/assets/fonts/Benzin-Bold.woff') format('woff');
}
@font-face {
  font-family: "Benzin-ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local('Benzin-ExtraBold'), url('../src/assets/fonts/Benzin-ExtraBold.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Saira:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;;
}

.mh-100vh {
  min-height: 100vh;
}