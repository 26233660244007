.navbar {
    font-family: "Saira", sans-serif;
    .nav-link {
        font-family: "Saira", sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        padding: 10px 20px !important;
    }
}

.navbar-scroll {
    background: rgba(22, 124, 146, 0.7);
    backdrop-filter: blur(10.4px);
}