.hero-section {
    background-image: url(../../assets/hero-bg.png);
    min-height: 100vh;
    background-size: cover;
    background-position: center;

    .car {
        position: absolute;
        right: 50px;
        bottom: 50px;
    }

    .flight {
        position: absolute;
        right: 30%;
        top: 80px;
    }

    .title-content {
        padding-top: 200px;
        .text1 {
            font-family: "Benzin-Medium";
            color: #fff;
            font-size: 32px;
            line-height: 31px;
        }
        .text2 {
            font-family: "Benzin-Medium";
            color: #0edb6f;
            font-size: 72px;
            line-height: 72px;
        }
        .text3 {
            font-family: "Benzin-Medium";
            color: #fff;
            font-size: 24px;
            line-height: 57px;
        }
        .introducing {
            margin-left: 230px;
        }
    }
    .hero-control {
        position: absolute;
        bottom: 50px;
        left: 20px;
        a {
            display: inline-block;
            font-family: "Saira", sans-serif;
            font-size: 14px;
            color: #fff;
            margin: 0 20px;
            text-decoration: none;
            cursor: pointer;
            img {
                margin-bottom: 10px;
            }
        }
        a:hover {
            color: #fff;
        }
    }
}

.our-journey {
    background-image: url(../../assets/journey-bg.png);
    background-size: cover;
    background-position: center;
    .title {
        color: #0cb56c;
        font-family: "Benzin-Bold";
    }
    p {
        color: #fff;
    }
    .journey-box {
        background: rgba(22, 124, 146, 0.3);
        border: 1px dashed #0d998c;
        backdrop-filter: blur(18.85px);
        border-radius: 10px;
        height: 100%;
        padding: 25px;
        text-align: center;
        color: #fff;
        img {
            margin-bottom: 20px;
        }
        .text1 {
            font-family: "Saira", sans-serif;
            font-weight: 700;
            font-size: 32px;
        }
        .text2 {
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.why-choose {
    background: linear-gradient(180deg, #eefeff 0%, rgba(255, 255, 255, 0) 100%);
    padding: 80px 0;

    .text1 {
        color: #000;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        color: #0cb56c;
        font-family: "Benzin-Bold";
        font-size: 36px;
        line-height: 44px;
    }
    .tag {
        background: linear-gradient(143.7deg, rgba(189, 237, 245, 0.44) 23.62%, #ffffff 71.91%);
        border: 1px solid #bdedf5;
        box-shadow: 0px 4px 13.3px rgba(0, 0, 0, 0.09);
        border-radius: 25px;
        padding: 10px;
        font-family: "Saira", sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #000;
    }
    .experience {
        color: #fff;
        background: linear-gradient(137.65deg, #0cb56c 4.7%, #058a9f 95%);
        border-radius: 15px;
        font-family: "Saira", sans-serif;
        font-weight: 700;
        position: absolute;
        left: 0px;
        top: 100px;
        padding: 40px 100px;
        .text3 {
            font-size: 48px;
        }
        .text4 {
            font-size: 20px;
        }
    }
}

.why-choose::before {
    content: url(../../assets/why-choose-leaf.svg);
    position: absolute;
    bottom: 10px;
}

.why-choose::after {
    content: url(../../assets/why-choose-car.png);
    position: absolute;
    top: 0;
    right: 0;
}

.eco-friendly {
    .text1 {
        color: #000;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        background: -webkit-linear-gradient(45deg, #09b288, #058a9f 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Benzin-Bold";
        font-size: 36px;
        line-height: 44px;
    }
    .img-title {
        width: 384px;
        height: 67px;
        background: rgba(9, 178, 136, 0.7);
        backdrop-filter: blur(4.3px);
        border-radius: 25px 0px 0px 0px;
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 24px;
        right: 0;
        bottom: 0;
    }
}

.corporate-bookings {
    background-image: url(../../assets/corporate-bg.jpg);
    background-size: cover;
    background-position: center;
    padding: 50px 20px;
    .text1 {
        color: #fff;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        color: #0cb56c;
        font-family: "Benzin-Bold";
        font-size: 36px;
        line-height: 44px;
    }
    p {
        color: #d9d9d9;
    }
    .feature {
        color: #fff;
        border-right: 1px solid #0c2d3e;
    }
    .feature:last-child {
        border-right: 0;
    }
}

.drive-with-us {
    background-image: url(../../assets/drive-with-us-bg.png);
    background-size: cover;
    background-position: center;
    padding: 80px 0px;
    .text1 {
        color: #000;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        color: #0cb56c;
        font-family: "Benzin-Bold";
        font-size: 32px;
        line-height: 35px;
    }
    p {
        color: #000;
    }
    .text3 {
        color: #058a9f;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }
    .text4 {
        font-size: 18px;
    }

    .news-banner {
        background: #e6f9fb;
        border-radius: 15px;
        padding: 10px;
        max-width: 1140px;
        margin: 0 auto 50px auto;
        .banner1 {
            background-image: url(../../assets/newsbanner-img01.png);
            background-size: cover;
            background-position: center;
            min-height: 180px;
            color: #053247;
            font-size: 24px;
            font-weight: bold;
            padding: 0 40px;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    .followus {
        max-width: 1140px;
        margin: 0 auto;
        border: 1px solid #058a9f;
        padding: 10px;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 500;
        .btn {
            background: linear-gradient(93.39deg, #0cb56c 0.35%, #058a9f 96.96%);
            border-radius: 10px;
            border: 0;
            width: 230px;
            height: 56px;
            font-weight: 600;
            font-size: 20px;
        }
        .line {
            border-left: 1px solid #0d998c;
            min-height: 38px;
        }
        .link {
            color: #058a9f;
            text-decoration: none;
        }
    }
}

.testimonials {
    background-image: url(../../assets/testimonials-bg.png);
    background-position: top right;
    background-repeat: no-repeat;
    padding: 80px 0;

    .text1 {
        color: #000;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        color: #0cb56c;
        font-family: "Benzin-Bold";
        font-size: 32px;
        line-height: 35px;
    }
    .frame-bg {
        background-image: url(../../assets/testimonial-frame-bg.png);
        background-size: cover;
        background-position: top;
        padding: 80px 60px;

        .message-sec {
            .message-box {
                background: #ffffff;
                border: 2px solid rgba(192, 231, 234, 0.5);
                box-shadow: 0px 4px 56.5px rgba(0, 0, 0, 0.13);
                border-radius: 15px;
                font-size: 14px;
                color: #515151;
                line-height: 18px;
                padding: 25px 10px;
            }
        }
        .message-sec-right-pad {
            margin-right: 70px;
        }
        .message-sec-left-pad {
            margin-left: 110px;
        }
        .more-btn {
            margin: 60px 0 0 50px;
        }
    }

    .app-bg {
        background-image: url(../../assets/app-bg.png);
        background-size: cover;
        background-position: top;
        height: 100%;
        .img1 {
            position: absolute;
            left: 80px;
            top: 180px;
        }
        .img2 {
            position: absolute;
            right: 30px;
            top: 60px;
        }
        .store-btns {
            padding-top: 580px;
        }
    }
}

.testimonials::before {
    content: url(../../assets/testominal-car.png);
    position: absolute;
    bottom: 10px;
}

.corporates {
    .text1 {
        color: #000;
        font-family: "Benzin-Regular";
        font-size: 16px;
    }
    .text2 {
        color: #0cb56c;
        font-family: "Benzin-Bold";
        font-size: 32px;
        line-height: 35px;
    }
    .city-bg {
        background-image: url(../../assets/city-outline-footer-bg.png);
        background-size: cover;
        background-position: bottom;
        min-height: 300px;
    }
    .corporate-box-sec {
        position: relative;
    bottom: -95px;
    }
    .corporate-img {
        position: absolute;
        width: 100%;
        top: 10%;
        left: 0;
    }
    .corporate-text {
        position: absolute;
        width: 100%;
        bottom: 15px;
        font-family: "Saira", sans-serif;
        font-size: 20px;
        color: #C3F7EA;
        font-weight: 500;
        left: 0;
        .title {
            font-weight: 600;
        }
    }
}

footer {
    background-image: url(../../assets/footer-bg.png);
    background-size: cover;
    min-height: 200px;
    color: #fff;
    .sub-sec1 {
        padding-top: 150px;
        .sub-icon {
            height: 80px;
        }
        a {
            color: #fff;
            text-decoration: none;
        }
    }
    .subscribe-input {
        border:1px solid #0CB56C;
        height: 45px;
        color: #fff;
        background-color: transparent;
        border-radius: 8px;
        padding: 0 10px;
    }
    .btn {
        background: linear-gradient(93.39deg, #0cb56c 0.35%, #058a9f 96.96%);
        border-radius: 10px;
        border: 0;
        width: 230px;
        height: 56px;
        font-weight: 600;
        font-size: 20px;
    }
    hr {
        border-color: #167970;
    }
    .copyright-text {
        color: #C0E7EA;
        a {
            color: #C0E7EA; 
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .hero-section .flight {
        display: none;
    }
    .hero-section .car {
        display: none;
    }
    .hero-section .title-content .introducing {
        margin-left:0 ;
        max-width: 80%;
        margin-top: 30px;
    }
    .hero-section .title-content {
        text-align: center;
    }

    .hero-section .title-content .text1 {
        font-size: 20px;
        line-height: 20px;
    }
    .hero-section .title-content .text2 {
        font-size: 38px;
        line-height: 50px;
    }
    .hero-section .title-content .text3 {
        font-size: 18px;
        line-height: 20px;
    }

    .hero-section .hero-control {
        position: relative;
        bottom: 0;
        left: 0;
        text-align: center;
    }
    .hero-section .hero-control a {
        margin: 0 5px;
    }

    .hero-section .hero-control a img {
        width: 50px;
    }

    .our-journey {
        padding-left: 20px;
        padding-right: 20px;
    }

    .our-journey .journey-box {
        display: flex;
    text-align: left;
    margin-bottom: 20px;
    height: auto;
    }

    .our-journey .journey-box img {
        margin: 0 20px 0 0;
        width: 50px;
    }

    .testimonials .frame-bg .more-btn {
        margin: 0;
    }
    
}

@media only screen and (min-width: 601px) {
    .hero-section .car2 {
        display: none;
    }
    
 }